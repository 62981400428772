import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduledEvent } from '../model/scheduled-event';
import { StorageNames } from '../_contants/storage-names';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  Url: any;
  header = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  })

  constructor(public http: HttpClient) {
    this.Url = localStorage.getItem(StorageNames.apiStaticUrl);
  }

  //  first step verify email.
  verifyEmail(url, data) {
    return this.http.post(url, data);
  }

  // login api
  login(endpoint, data) {
    this.Url = localStorage.getItem(StorageNames.apiStaticUrl)
    return this.http.post<HttpResponse<any>>(this.Url + endpoint, data,
      { observe: 'response', headers: this.header })
  }


  // common method for getting data by passing endpoint of api.
  getData(endpoint): Observable<any> {
    this.Url = localStorage.getItem(StorageNames.apiStaticUrl)
    return this.http.get<any>(this.Url + endpoint);
  }

  //  common method for sending data by passing endpoint of api.
  sendData(endpoint, data) {
    this.Url = localStorage.getItem(StorageNames.apiStaticUrl)
    return this.http.post(this.Url + endpoint, data);
  }

  //get scheduled date according to calendar view (month/week/day)
  getScheduleDate(endpoint): Observable<ScheduledEvent[]> {
    return this.http.get<any>(this.Url + '/' + endpoint)
      .pipe(
        map(response => response.map(data => new ScheduledEvent(data.clinicianCalenderScheduleid, data.startDateTime, data.endDateTime, data.summary, data.location, data.description, JSON.parse(data.attendees).subject_id, data.visitType))
        )
      )
  }

  updateData(endpoint, data) {
    this.Url = localStorage.getItem(StorageNames.apiStaticUrl)
    return this.http.put(this.Url + endpoint, data);
  }

  addScheduleDate(endpoint: string, data): Observable<any> {
    return this.http.post<any>(this.Url + '/' + endpoint, data)
  }

  getShifInfo(endpoint): Observable<any> {
    return this.http.get<any>(this.Url + '/' + endpoint).pipe(
      map(result => result[0])
    )
  }
  addSettings(endpoint): Observable<any> {
    return this.http.get<any>(this.Url + '/' + endpoint).pipe(
      map(result => result[0])
    )


  }

  getAdeverseEvent(endpoint: string): Observable<any> {
    return this.http.get<any>(this.Url + '/' + endpoint)
  }

  getPatientBySearch(endpoint: string, data) {
    return this.http.post<any>(this.Url + endpoint, data);
  }

  forgotPassword(endpoint, data) {
    return this.http.put(localStorage.getItem('patitentlyUrl') + '/' + endpoint, data)
  }

  getPatientCrfDetail(endpoint): Observable<any> {
    return this.http.get(this.Url + endpoint)
  }

  updateCrfStatus(endpoint, data) {
    return this.http.post(this.Url + endpoint, data)
  }

  getNotification(endpoint) {
    return this.http.get(this.Url + endpoint)
  }

  getBroadcastMessage(endpoint) {
    return this.http.get(this.Url + endpoint)
  }

  markNotificationDelivered(endpoint, msgId) {
    return this.http.post(this.Url + endpoint, { msgId })
  }

}
