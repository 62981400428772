import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent {
  forgetForm: FormGroup;
  loadingCircle = false
  error = false
  emailSended = false
  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
  ) {
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })

    const email = this.route.snapshot.queryParams['email']
    if (email) this.forgetForm.patchValue({ email })

  }
  get formControls() { return this.forgetForm.controls; }


  forgetPassword() {
    if(this.loadingCircle == false){
      this.loadingCircle = true;
      this.userService.sendData('/forgetpassword', this.forgetForm.value).subscribe(res => {
        this.emailSended = true
        this.loadingCircle = false
        setTimeout(() => this.router.navigate(['login']), 3000);
      }, err => {
        this.error = true
        this.loadingCircle = false
      })
    }
  }
  checkEmail() {
    this.error = false
    this.emailSended = false
  }
}
