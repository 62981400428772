import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private title: Title) {
    var firebaseConfig = {
      apiKey: "AIzaSyDNE5bDXk38CGX3-1frZaMmFduvU_wzmuk",
      authDomain: "patiently.firebaseapp.com",
      projectId: "patiently",
      storageBucket: "patiently.appspot.com",
      messagingSenderId: "922127761580",
      appId: "1:922127761580:web:03cadc72b6251cff869f09"
    };
    // // Initialize Firebase
    firebase.initializeApp(firebaseConfig)
    this.title.setTitle('Patiently')
  }

}
