import { tokenName } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenNames } from '../_contants/token-names';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  constructor(
    private router: Router,
  ) {}
  clearAuthData() {
    localStorage.removeItem(TokenNames.accessTokenName);
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

          if(localStorage.getItem(TokenNames.accessTokenName)){
            return true;
          }else{
            this.router.navigate(['login']);
            return false;
          }
}
  
  
}
