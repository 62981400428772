import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-selectdashboard',
  templateUrl: './selectdashboard.component.html',
  styleUrls: ['./selectdashboard.component.scss']
})
export class SelectdashboardComponent implements OnInit {
  dashboardList: any[];
  selectedDashboardValue;
  selectedValue
  studiesData
  showUpdateAlert: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private title: Title,
  ) { }

  ngOnInit() {
    this.getStudies()
    this.title.setTitle('Select Dashboard')
  }

  updateValue() {
    var obj = {
      "siteId": this.selectedValue.siteId,
      "studyId": this.selectedValue.studyId,
      "status": true
    }
    this.userService.updateData('/studies', obj).subscribe(response => {
      if (response) {
        localStorage.setItem('studiesvalue', JSON.stringify(this.selectedValue))
        this.userService.getData('/profile').subscribe(res => {
          localStorage.setItem('userDetail', JSON.stringify(res));
        })
        this.router.navigate(['dashboard']);
      }
    })

  }
  showAlert(value) {
    this.selectedValue = value
    this.selectedDashboardValue = value.studyName;
    this.showUpdateAlert = true
  }
  cancelAlert() {
    this.showUpdateAlert = false
  }

  getStudies() {
    this.userService.getData('/studies').subscribe(response => {
      this.studiesData = response
    })
  }

}
