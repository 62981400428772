import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import jwt_decode from "jwt-decode";
import { TokenNames } from '../_contants/token-names';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  token: any;
  tokenExpire: boolean = false;
  constructor(
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem(TokenNames.accessTokenName) != null) {
      this.token = localStorage.getItem(TokenNames.accessTokenName);
      var decoded = jwt_decode(this.token);
      var currentEpochTime = parseInt((Date.now()).toString().slice(0, 10));
      if (decoded['exp'] > currentEpochTime) {
        this.tokenExpire = false;
        this.router.navigate(['dashboard']);
        return false;
      } else {
        localStorage.clear();
        return true;
      }
    }else{
      return true;
    }
  }

}
