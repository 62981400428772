<div class="login-bg">
    <div class="login-form">
      <div class="login-header">
        <img src="../../assets/logo.svg">
      </div>
      <form [formGroup]="resetForm"(ngSubmit)="resetPswd()">
        <a>Please reset your password!</a>
  <div class="mb-3">
     <div class="next-arrow">
    <input type="password" class="form-control" formControlName="newPassword" placeholder="New Password">
    <div *ngIf="isSubmitted && formControls.newPassword.errors">
        <div *ngIf="formControls.newPassword.errors.required">New Password is required</div>
      </div>
    <!-- <span (click)="showPswd()"></span> -->
  </div>
  </div>

  <div class="mb-3">
    <input type="password" class="form-control" formControlName="retypePassword" placeholder="Confirm Password">
    <div *ngIf="isSubmitted && formControls.retypePassword.errors">
        <div *ngIf="resetForm.formControls.errors.required">Retype New Password is required</div>
      </div>
  </div>
  <p *ngIf="formControls.retypePassword.touched && (resetForm.controls.newPassword.value != resetForm.controls.retypePassword.value)">Retype password doesn't match.</p>
  <button type="submit" [disabled]="updatingCircle" class="btn btn-primary">Reset <i class="fa fa-spinner fa-spin" *ngIf="updatingCircle"></i></button>
</form>
    </div>
    <div class="popModal" *ngIf="showPasswordAlert">
      <div class="asking-popup">
         <!-- <span class="close-popup" (click)="hidePasswordAlert()"></span> -->
         <h2>Your password is updated, Please login again with new password</h2>
         <div class="action">
            <button type="submit" class="btn btn-primary accept" (click)="resetPasswordAlert()">Ok</button>
         </div>
      </div>
    </div>
    </div>  