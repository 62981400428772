<div class="login-bg">
  <div class="login-form">
    <div class="login-header">
      <img src="../../assets/logo.svg">
    </div>
    <form [formGroup]="loginForm">
      <div class="mb-3">

        <div class="next-arrow">
          <input type="email" class="form-control" (input)="emailValidation()" formControlName="username"
            placeholder="Login Id" (keyup.enter)=verifyEmail()>
          <span (click)="verifyEmail()" *ngIf="!showEmailLoder && !showPassword"></span>
          <i class="fa fa-spinner fa-spin" *ngIf="showEmailLoder"></i>
        </div>

        <div *ngIf="isSubmitted && formControls.username.errors">
          <div *ngIf="formControls.username.errors.required">Login Id is required</div>
        </div>
        <div *ngIf="errorShow">
          <div *ngIf="errorShow" class="error">This login id is not registered for any study”. Please check with your study
            coordinator</div>
        </div>

        <div
          *ngIf="loginForm.get('username').hasError('pattern') && (loginForm.get('username').dirty || loginForm.get('username').touched)">
          <div class="error" *ngIf="loginForm.get('username').hasError('pattern')">Login id is Invalid</div>
        </div>

        <small *ngIf="!showPassword">Please use your registered login id for the study.</small>
      </div>

      <div *ngIf="!errorShow">
        <div class="mb-3" *ngIf="showPassword && (!errorShow)">
          <div _ngcontent-gph-c45="" class="next-arrow">
            <input type="password" class="form-control" formControlName="password" placeholder="Password"
              (input)="passValidation()" (keyup.enter)=login()>
            <span *ngIf="!loadingCircle" (click)="login()"></span>
            <i class="fa fa-spinner fa-spin" *ngIf="loadingCircle"></i>
          </div>
          <div *ngIf="isSubmitted && formControls.password.errors">
            <div *ngIf="formControls.password.errors.required">Password is required.</div>
          </div>
        </div>
        <div class="mb-3" *ngIf="incorrectEmailPass">
          <div>
            <div class="error">“The password not correct”. If you forgot password, please reset or check with your study
              coordinator.</div>
          </div>
        </div>
        <a *ngIf="showPassword" routerLink="/forgetPassword" [queryParams]="{email: formControls.username.value}">Forgot
          password</a>
      </div>
    </form>
  </div>
</div>