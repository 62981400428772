import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetForm: FormGroup;
  showPassword: boolean = false;
  passwordMismatch: boolean = false;
  isSubmitted = false;
  updatingCircle:boolean = false;
  showPasswordAlert:boolean = false;
  email
  constructor(
    private userService: UserService,
    private router: Router,
    private title: Title,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.parent.paramMap.get('id')

    this.resetForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      retypePassword: ['', Validators.required]
    });

    this.title.setTitle('Reset Password')

  }

  get formControls() { return this.resetForm.controls; }


  showPswd() {
    if (this.resetForm.controls.newPassword.value != '') {
      this.showPassword = true;
    }
  }

  hidePasswordAlert() {
    this.showPasswordAlert = false;
  }


  resetPswd() {
    this.updatingCircle = true
    if (this.resetForm.controls.newPassword.value == this.resetForm.controls.retypePassword.value) {
      this.isSubmitted = true;
      this.passwordMismatch = false;
      var email = localStorage.getItem('email');
      localStorage.removeItem('email');

      var obj = {
        'password': this.resetForm.controls.newPassword.value
      }

      this.userService.sendData('/updatepassword', obj).subscribe(response => {
        console.log(response)

        if (response['status'] == "SUCCESS") {
           this.updatingCircle = false
          this.showPasswordAlert = true
        }
      })

    } else {
      this.passwordMismatch = true;
    }

  }

  resetPasswordAlert(){
      localStorage.clear();
      this.router.navigate(['']);
  
  }

}
