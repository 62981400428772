
    <div class="login-bg">
      <div class="welcome-header">
        <img src="../../assets/logo-horizontal.png">
      </div>
      <div class="welcome-area">
        <p><span>Welcome!!</span>Please select study dashboard</p>  
        <form>  
        <div class="choose-dashboard">
          <button type="submit" class="btn btn-primary" [ngClass]="{'secondary-btn': selectedDashboardValue === data.studyName}" (click)="showAlert(data)" *ngFor="let data of studiesData">{{data.studyName}}</button>
        </div>      
        </form>       
      </div>
      <div class="popModal" *ngIf="showUpdateAlert">
        <div class="asking-popup">
          <p>Do you want to set this as default study dashboard?</p>
          <div class="action">
            <button type="submit" class="btn btn-primary accept" (click)="updateValue()">Yes</button>
            <button type="submit" class="btn btn-primary decline" (click)="cancelAlert()">No</button>
          </div>
        </div>
      </div>
      </div>  


