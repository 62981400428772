<div class="login-bg">
    <div class="login-form">
      <div class="login-header">
        <img src="../../assets/logo.svg">
      </div>
      <form  [formGroup]="forgetForm"(ngSubmit)="forgetPassword()">
        <a href="javascript:void(0)">Forgot password?</a>
        <small><span>It happens !!</span>Please submit password reset request, and we will send temporary password to your email id.</small>
  <div class="mb-3">
     <div class="next-arrow">
    <input type="email" class="form-control"  placeholder="Email" formControlName="email" (input)=checkEmail()>  
    <div *ngIf="forgetForm.get('email').hasError('email')">
      <div *ngIf="forgetForm.get('email').touched">Email is required</div>
    </div>
  </div>
  </div>
  <div class="mb-3" *ngIf="error">
    <div >
        <div  class="error">Something went wrong</div>
      </div>
  </div>
  <div class="mb-3" *ngIf="emailSended">
    <div >
        <div  class="success">You received a temporary password on registered email id.</div>
      </div>
  </div>
  <button type="submit" *ngIf="!emailSended" class="btn btn-primary" [disabled]="!forgetForm.valid">Submit<i class="fa fa-spinner fa-spin" *ngIf="loadingCircle"></i></button>
</form>

    </div>
    </div>  