
export class ScheduledEvent {
    start: Date
    end: Date

    constructor(
        public id: string,
        startDateTime: string,
        endDateTime: string,
        public title: string,
        public location: string,
        public description: string,
        public attendees,
        public visitType: string
    ) {
        this.start = new Date(startDateTime)
        this.end = new Date(endDateTime)
    }
}