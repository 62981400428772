import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventService {
  public headerActive = new Subject<any>();
  private scheduleUpdated = new Subject<any>();
  private reload = new Subject<any>();

  private _scheduleCall = new BehaviorSubject<number>(null)
  scheduleCall$ = this._scheduleCall.asObservable()

  private _eventType = new BehaviorSubject<string>(null)
  eventType$ = this._eventType.asObservable()

  constructor() { }

  headerEvent(data: any) {
    localStorage.setItem('active', data);
    this.headerActive.next(data);
  }
  getReload(): Subject<any> {
    return this.reload
  }
  setReload(flag: boolean) {
    this.reload.next(flag);
  }
  getHeaderEvent(): Subject<any> {
    return this.headerActive
  }
  setUpdatedSchedule(flag: boolean) {
    this.scheduleUpdated.next(flag);
  }
  getUpdatedSchedule() {
    return this.scheduleUpdated;
  }
  set scheduleCall(name: number) {
    this._scheduleCall.next(name)
  }

  set eventType(name: string) {
    this._eventType.next(name)
  }

}
