import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventService } from '../shared/services/event.service';
import { UserService } from '../shared/services/user.service';
import { StorageNames } from '.././shared/_contants/storage-names';
import { TokenNames } from '../shared/_contants/token-names';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showPassword: boolean = false;
  loadingCircle: boolean = false;
  loginForm: FormGroup;
  isSubmitted = false;
  errorShow = false
  studies: any = []
  trueCount
  incorrectEmailPass = false
  showEmailLoder = false
  constructor(
    private title: Title,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      password: ['', Validators.required]
    });

    this.title.setTitle('Login')
  }

  // getting Url for verify email.
  getVerifyEmailUrl() {
    if (environment.production) {
      return environment.verifyEmailUrl;
    } else {
      return environment.verifyEmailUrl;
    }
  }

  get formControls() { return this.loginForm.controls; }

  // Login method(authentication);
  login() {
    this.isSubmitted = true;
    this.loadingCircle = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.userService.login('/login', this.loginForm.value).subscribe((response) => {
      if (response.status == 200) {
        localStorage.setItem(TokenNames.accessTokenName, response.headers.get('x-amzn-remapped-authorization'))
        //temporary for testing
        localStorage.setItem(StorageNames.apiStaticUrl, 'http://localhost:8082/clinician') 
        if (parseInt(response.headers.get('firstlogin')) == 1) {
          this.router.navigate(['resetPassword']);
        } else {
          this.getStudies()
        }
      } else if (response.status == 403) {
      }
    }, err => {
      this.incorrectEmailPass = true
      this.loadingCircle = false
    })

  }


  //  email verification method.
  verifyEmail() {
    if (this.loginForm.controls.username.value != '') {
      this.showEmailLoder = true
      const Url = this.getVerifyEmailUrl()
      localStorage.setItem(StorageNames.userEmail, this.loginForm.controls.username.value)
      this.userService.verifyEmail(Url, { 'email': this.loginForm.controls.username.value }).subscribe(response => {
        if (response['url']) {
          this.showEmailLoder = false
          localStorage.setItem(StorageNames.apiStaticUrl, response['url']+'/clinician')
          this.showPassword = true;
        } else {
          this.showEmailLoder = false
          this.errorShow = true;
        }
      }, err => {
        this.showEmailLoder = false
        this.errorShow = true;
      })
    }
  }

  //  email and password validation.

  emailValidation() {
    this.errorShow = false
    this.incorrectEmailPass = false
    if (this.loginForm.controls.username.value.length == 0) {
      this.showPassword = false;
    }
  }

  passValidation() {
    this.errorShow = false
    this.incorrectEmailPass = false
  }


  // get studies dashboard method.
  getStudies() {
    this.eventService.headerEvent('Dashboard');
    this.userService.getData('/studies').subscribe(response => {
      this.studies = response
      this.trueCount = 0
      this.studies.forEach((element, index) => {

        if (this.trueCount == 0) {
          if (element.default == true) {
            this.trueCount++;
            localStorage.setItem('studiesvalue', JSON.stringify(element))
            this.getUserProfile();
          }
        }

        if ((index + 1) == this.studies.length) {
          if (this.trueCount == 1) {
            this.router.navigate(['/dashboard'])
          } else {
            this.router.navigate(['/selectDashboard'])
          }
        }
      })
      this.loadingCircle = false;
    })
  }

  forgotPassword() {
    if (this.loadingCircle == false) {
      this.userService.sendData('/is_forgetpassword', { 'email': this.loginForm.controls.username.value }).subscribe(res => {
      })
    }
  }

  getUserProfile() {
    this.userService.getData('/profile').subscribe(res => {
      localStorage.setItem('userDetail', JSON.stringify(res));
    })
  }

}
